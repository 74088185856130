@media screen and (max-width: 768px) {
    .header-container {
        margin-right: 20px;
    }

    .header {
        justify-content: center;
    }

    .buttons {
       margin-bottom: 20px;
    }
}

@media screen and (min-width: 768px) {
    .main-page {
        display: grid;
    }
}