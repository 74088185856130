.first-header {
    text-transform: uppercase;
    color: #1e3a8a;
    font-weight: 100;
}

.header {
    color: #1e3a8a;
}

.inner-container {
    border: 1px solid #1e3a8a;
    border-radius: 30px;
    border-width: 10px;
    margin-bottom: 40px;
    background-color: #fff;
}

.paragraph {
    color: #1e3a8a;
    padding-bottom: 20px;
    font-weight: 50;
}

.icon {
    width: 40px;
    height: 40px;
}

.icon--white {
    fill: #fff;
}

.list {
    list-style: none;
    padding-left: 3rem;

}


.collapsible__content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s;
}

.collapsible--expanded .collapsible__chevron {
    transform: rotate(0);
}

.collapsible--expanded .collapsible__content {
    max-height: 100vh;
    opacity: 1;
}


.nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 1rem;
    align-items: center;
}

.nav__list {
    width: 100%;
    margin: 0;
    margin-right: 60px;
}

.nav__item {
    padding: 0.5rem 0.2rem;
    border-bottom: 1px solid #222;
}

.nav__item > a {
    color: #d2d0db;
    transition: color 0.3s;
}

.nav__item > a:hover {
    color: #fff;
}

.nav__toggler {
    opacity: 0.5;
    transition: box-shadow .15s;
    cursor: pointer;
}

.nav.collapsible--expanded .nav__toggler {
    opacity: 1;
    box-shadow: 0 0 0 3px #666;
    border-radius: 5px;
}

.nav__brand {
    transform: translateY(5px);
}

@media screen and (min-width: 768px){
    .nav__toggler {
        display: none;
    }

    .nav__list {
        width: auto;
        display: flex;
        font-size: 1.6rem;
        max-height: 100%;
        opacity: 1;

    }
    .nav__item {
        border: 0;
        margin-right: 2rem;
    }

    .first-container {
        display: flex;
        align-items: center;
        justify-content: space-around;
    }


}

@media screen and (min-width: 768px) {
    .svg-logo {
        margin-right: 390px;

    }
}

@media screen and (max-width: 768px) {
    .profile-span {
        margin-left: 20px;
    }
}