.header {
    color: #1e3a8a;
    padding-top: 20px;
}

.paragraph {
    color: #1e3a8a;
    padding-bottom: 20px;
    font-size: 30px;
}

.inner-container {
    border: 1px solid #1e3a8a;
    border-radius: 30px;
    border-width: 10px;
    margin-bottom: 40px;
    background-color: #fff;
}