@media screen and (min-width: 768px) {
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    .continue-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: center;
    }
}

@media screen and (max-width: 768px){
    .text {
        font-size: 30px;
        margin-left: 20px;
    }

    .reset-password-button {
        /* width: 200px; */
        display: flex;
        flex-direction: column;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
}
